label.MuiFormControlLabel-root {
  margin-left: 0px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  border-radius: 4px;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.uploadYourLogoWrap input.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 30px 18px;
}

.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline {
  border: none;
}

.agreeTermsAnchor {
  color: #000;
}

.alreadyAccount {
  margin-top: 38px;
  margin-bottom: 63px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.loginAnchor {
  color: #c43c42;
  font-weight: 700;
}

div.backdropSignUp {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}

.confirmEmailAddressSubtitle {
  margin-top: 45px;
  font-size: 16;
  font-weight: 700;
  color: #000;
  width: 100%;
  text-align: center;
}

.confirmEmailAddressDescription {
  margin-top: 12px;
  width: 348px;
  font-size: 16;
  font-weight: 400;
  color: #000;
  text-align: justify;
}

.confirmEmailAddressNeedHelp {
  margin-top: 25px;
  margin-bottom: 110px;
  font-size: 16;
  font-weight: 400;
  color: #000;
}

.confirmYourAccountCheckbox {
  margin-bottom: 63px;
}

.haveBranchesGrid {
  max-width: 207px;
}
.haveBranchesTitle {
  margin-bottom: 6px;
}

.howManyBranchesGrid {
  margin-left: 80px;
}
.howManyBranchesTitle {
  margin-bottom: 28px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButtonGroup-grouped.MuiButtonGroup-groupedHorizontal.MuiButtonGroup-groupedContained.MuiButtonGroup-groupedContainedHorizontal.MuiButtonGroup-groupedContainedPrimary.howManyBranchesButton {
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
}

.howManyBranchesQuantity {
  background-color: #f1f1f1;
  height: 30px;
  width: 63px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #4f4f4f;
}

.dayGrid {
  width: 120px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 767px) {
  .confirmEmailAddressDescription {
    width: 90%;
  }
}
